import { ReactElement } from 'react';

import { GetStaticProps } from 'next';

import { SubpageLayout } from '@features/landing-page';
import { OpenProductionAppPage } from '@features/open-app';

export const getStaticProps: GetStaticProps = ({ locale }) => {
  return {
    props: {
      messages: {
        ...require(`../public/locales/${locale}.json`)
      }
    }
  };
};

OpenProductionAppPage.getLayout = function getLayout(page: ReactElement) {
  return <SubpageLayout>{page}</SubpageLayout>;
};

export default OpenProductionAppPage;
